@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'DMSansLight';
  src: url('/public/assets/font/DMSans_18pt-Light.tff') format('opentype'); 
  font-weight: normal; 
  font-style: normal; 
} */

/* @font-face {
  font-family: 'DMSansLight';
  src: url('/public/assets/font/DMSans_18pt-Light.ttf') format('opentype'); 
  font-weight: normal; 
  font-style: normal; 
} */

.DMSansLight{
  font-family: "DM Sans", sans-serif;
}



html {
  scroll-behavior: smooth;
}


*{
  /* font-family: 'Clash Display', sans-serif; */
  /* font-family: "Lato", sans-serif; */
  /* font-family: "Heebo", sans-serif; */
  font-family: "DM Sans", sans-serif;

}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #393231; */
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zoom-out {
  transform: scale3d(1.0, 1.0, 1.0);
  opacity: 1;
  transition: transform 20s ease, opacity 0.8s ease;  /* Apply both zoom and fade transitions */
}

.zoom-in {
  transform: scale3d(1.2, 1.2, 1.2);
  opacity: 1;
  transition: transform 20s ease, opacity 0.8s ease;  /* Same for zoom-in with opacity */
}

.fade-in {
  opacity: 1;
  transition: transform 20s ease, opacity 0.8s ease;  /* Apply fade-in transition */
}

.fade-out {
  opacity: 0;
  transition: transform 20s ease, opacity 0.8s ease;  /* Apply fade-out transition */
}

.fade-in-experience {
  opacity: 1;
  animation: fadeInExperience 3s forwards;
}

.fade-out-experience {
  opacity: 0;
  animation: fadeOutExperience 3s forwards;
}

@keyframes fadeInExperience {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutExperience {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.focus-in {
  animation-name: text-focus-in;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
    
  /* shorthand
  animation: text-focus-in 1s linear 0s 1 normal none;*/
}

@keyframes text-focus-in {
			
  0% {
    filter:blur(12px);
    opacity:0;
  }
  100% {
    filter:blur(0);
    opacity:1;
  }
}

.text-negative {
  color: black;
  display: block;
  filter: invert(1);
  mix-blend-mode: difference;
}

.text-negative-remove {
  color: black!important;
  display: block!important;
  filter: invert(0)!important;
  mix-blend-mode: normal !important;
}

.lora-font {
  font-family: 'Lora';
  /* font-size: 3rem; */
}

.bg-gold{
  background-color: #b7955a;
}

.progress-button-light {
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.progress-button-light::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgb(248 250 252); 
  transition: width 0.5s ease;
  z-index: -1;
}

.progress-button-light:hover::after,
.progress-button-light.active::after {
  width: 100%;
}

.progress-button-light:hover,
.progress-button-light.active {
  color: black; /* text-black equivalent */
  z-index: 1;
}

.progress-button-dark {
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.progress-button-dark::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: rgb(0, 0, 0); 
  transition: width 0.5s ease;
  z-index: -1;
}

.progress-button-dark:hover::after {
  width: 100%;
}

.progress-button-dark:hover {
  color: rgb(255, 255, 255); /* text-black equivalent */
  z-index: 1;
}

.text-gold {
  color: #CE9E59
}

.animated-line {
  width: 0;
  animation: lineAppear 4s forwards;
}

@keyframes lineAppear {
  100% {
    width: 100%;
  }
}


.underline-animate::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #000000;
  transition: width 0.3s ease;
}

.underline-animate:hover::after {
  width: 100%;
}

.underline-animate-active::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #000000;
  transition: width 0.3s ease;
}

.glass-effect {
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(30px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
}


.storia-experience {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* gallery carousel */

.react-images__view-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.react-images__view-image img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.tolstoy-carousel-container {
  position: relative;
}

.tolstoy-carousel {
  top: 50%;
}

/* Keyframe for the shadow animation */
@keyframes slideShadow {
  0% {
    background-position: 100% 0;
    background-size: 0% 100%;
  }
  100% {
    background-position: 0% 0;
    background-size: 100% 100%;
  }
}

/* Keyframe for text sliding effect */
@keyframes slideText {
  0% {
    opacity: 0; /* Invisible */
  }
  50% {
    opacity: 0; /* Invisible */
  }
  100% {
    opacity: 1; /* Fully visible */
  }
}

/* Apply the animation to the section */
.shadow-background {
  height: fit-content;
  /* Gradient with customizable stops for fine-tuning shadow effect */
  background: linear-gradient(to left, 
    rgba(0, 0, 0, 0.9) 96%,  /* Fully opaque shadow */
    rgba(0, 0, 0, 0) 100%);  /* Fully transparent at 100% */
    
  background-size: 0% 100%;  /* Start with 0% width */
  background-repeat: no-repeat;
  animation: slideShadow 2s ease-in-out forwards;
}

/* Apply the animation to the section */
.shadow-background-no-animation {
  /* Gradient with customizable stops for fine-tuning shadow effect */
  background: linear-gradient(to left, 
    rgba(0, 0, 0, 0.9) 98%,  /* Fully opaque shadow */
    rgba(0, 0, 0, 0) 100%);  /* Fully transparent at 100% */
    
  background-size: 0% 100%;  /* Start with 0% width */
  background-repeat: no-repeat;
}

/* Apply the animation to the text */
.shadow-text {
  animation: slideText 3s ease-in-out forwards; /* Slide in the text */
  opacity: 0; /* Ensure text starts as invisible */
}

/* loading section */
/* .fade-out {
  opacity: 0;
  transition: opacity 3s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 3s ease-in-out;
} */
